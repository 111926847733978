import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a7e2840"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "bottom-box"
};
const _hoisted_3 = {
  class: "filters"
};
const _hoisted_4 = {
  class: "filtersbox"
};
const _hoisted_5 = {
  class: "filtersarrow"
};
const _hoisted_6 = {
  key: 0,
  class: "store-list"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "store-name"
};
const _hoisted_9 = {
  class: "name"
};
const _hoisted_10 = {
  key: 2,
  class: "isover"
};
const _hoisted_11 = {
  class: "location"
};
const _hoisted_12 = {
  class: "text-list"
};
const _hoisted_13 = {
  class: "text-item"
};
const _hoisted_14 = {
  class: "label"
};
const _hoisted_15 = {
  class: "text-item"
};
const _hoisted_16 = {
  class: "label"
};
const _hoisted_17 = {
  class: "bottombtn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_search = _resolveComponent("van-search");
  const _component_Selector = _resolveComponent("Selector");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_empty = _resolveComponent("van-empty");
  const _component_van_list = _resolveComponent("van-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_search, {
    modelValue: $data.searchname,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => $data.searchname = $event), $options.updatesearch],
    shape: "round",
    placeholder: "终端名称模糊查找",
    maxlength: "30"
  }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_Selector, {
    class: "selector first",
    value: $data.status,
    "onUpdate:value": _cache[1] || (_cache[1] = $event => $data.status = $event),
    options: $data.typeList,
    title: "计划状态",
    onChange: $options.getData
  }, null, 8, ["value", "options", "onChange"]), _createVNode(_component_Selector, {
    class: "selector first",
    value: $data.types,
    "onUpdate:value": _cache[2] || (_cache[2] = $event => $data.types = $event),
    options: $data.typesDate,
    title: "类型",
    onChange: $options.getData
  }, null, 8, ["value", "options", "onChange"]), _createVNode(_component_Selector, {
    class: "selector",
    value: $data.position,
    "onUpdate:value": _cache[3] || (_cache[3] = $event => $data.position = $event),
    options: $data.positionList,
    title: "任务",
    onChange: $options.getData
  }, null, 8, ["value", "options", "onChange"]), _createVNode(_component_Selector, {
    class: "selector",
    value: $data.province,
    "onUpdate:value": _cache[4] || (_cache[4] = $event => $data.province = $event),
    options: $data.provinceList,
    title: "省",
    onChange: $options.getCity
  }, null, 8, ["value", "options", "onChange"]), _createVNode(_component_Selector, {
    class: "selector",
    value: $data.city,
    "onUpdate:value": _cache[5] || (_cache[5] = $event => $data.city = $event),
    options: $data.cityList,
    title: "市",
    onChange: $options.getArea
  }, null, 8, ["value", "options", "onChange"]), _createVNode(_component_Selector, {
    class: "selector",
    value: $data.area,
    "onUpdate:value": _cache[6] || (_cache[6] = $event => $data.area = $event),
    options: $data.areaList,
    title: "区",
    onChange: $options.getData
  }, null, 8, ["value", "options", "onChange"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_icon, {
    name: "arrow"
  })])]), _createVNode(_component_van_list, {
    loading: $data.loading,
    "onUpdate:loading": _cache[7] || (_cache[7] = $event => $data.loading = $event),
    finished: $data.finished,
    onLoad: $options.onLoad
  }, {
    default: _withCtx(() => [$data.listData.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.listData, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "store-item",
        key: index,
        onClick: $event => $options.goPlanDetail(item.orgTerminalCode, item.type, item.status, item.planCode)
      }, [_createElementVNode("div", _hoisted_8, [item.type === 'NEW' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(item.status === 'CANCELLED' ? 'over' : 'new')
      }, "新", 2)) : _createCommentVNode("", true), item.type !== 'NEW' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(item.status === 'CANCELLED' ? 'over' : 'notnew')
      }, null, 2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_9, _toDisplayString(item.orgTerminalName), 1), item.status === 'CANCELLED' ? (_openBlock(), _createElementBlock("div", _hoisted_10, "已取消")) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_11, _toDisplayString(item.province) + _toDisplayString(item.city) + _toDisplayString(item.district), 1), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, "任务：" + _toDisplayString(item.positionName), 1)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("span", _hoisted_16, "计划发布时间：" + _toDisplayString(item.publishTime), 1), _createTextVNode(" " + _toDisplayString(item.updateDate), 1)])])], 8, _hoisted_7);
    }), 128))])) : (_openBlock(), _createBlock(_component_van_empty, {
      key: 1,
      description: "暂无数据"
    }))]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("button", {
    class: "btn1",
    onClick: _cache[8] || (_cache[8] = (...args) => $options.goback && $options.goback(...args))
  }, "返回")])]);
}