import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "core-js/modules/es.array.push.js";
import Selector from '@/components/Selector';
import { activateRateDetail, activateRateMonthDetail } from '@/api/wxwork';
import { terminalPlanList, provinceFind, cityFind, areaFind, positionList } from '@/api/wxproject';
import dayjs from 'dayjs';
export default {
  components: {
    [_Tab.name]: _Tab,
    [_List.name]: _List,
    [_Tabs.name]: _Tabs,
    [_Icon.name]: _Icon,
    [_Tag.name]: _Tag,
    [_Empty.name]: _Empty,
    [_Search.name]: _Search,
    Selector
  },
  data() {
    return {
      searchname: '',
      loading: false,
      finished: false,
      pageNumber: 0,
      provinceList: [],
      cityList: [],
      areaList: [],
      typeList: [{
        label: '不限',
        value: ''
      }, {
        label: '已取消',
        value: 'CANCELLED'
      }, {
        label: '正常',
        value: 'ACTIVATED'
      }],
      typesDate: [{
        label: '不限',
        value: ''
      }, {
        label: '新增',
        value: 'NEW'
      }, {
        label: '正常',
        value: 'NOMAL'
      }],
      positionList: [],
      position: '',
      status: '',
      types: '',
      province: '',
      city: '',
      area: '',
      listDatalength: 0,
      listData: [],
      totallist: 0
    };
  },
  mounted() {
    document.title = `我的终端计划${this.totallist}`;
    const {
      projectCode,
      month
    } = this.$route.query;
    // this.position = this.$route.query.position;
    this.status = this.$route.query.status;
    this.types = this.$route.query.types;
    if (this.$route.query.province) {
      this.province = this.$route.query.province;
      this.city = this.$route.query.city;
      this.area = this.$route.query.area;
    }
    this.getProvince();
    this.getPosition();
    this.getData1();
  },
  methods: {
    getData() {
      this.pageNumber = 0;
      terminalPlanList({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        pageNumber: this.pageNumber++,
        pageSize: 10,
        status: this.status,
        province: this.province,
        city: this.city,
        district: this.area,
        positionCode: this.position,
        terminalName: this.searchname,
        type: this.types
      }).then(res => {
        this.listData = res.data.data;
        console.log(res.data.data);
        document.title = `我的终端计划（${res.data.total}）`;
        if (this.listData.length >= res.data.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      });
    },
    getData1() {
      terminalPlanList({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        pageNumber: 0,
        pageSize: 10,
        status: this.status,
        province: this.$route.query.province,
        city: this.$route.query.city,
        district: this.$route.query.area,
        positionCode: this.$route.query.position,
        terminalName: this.searchname,
        type: this.types
      }).then(res => {
        document.title = `我的终端计划（${res.data.total}）`;
      });
    },
    getProvince() {
      provinceFind().then(res => {
        this.provinceList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.provinceList.unshift({
          label: '不限',
          value: ''
        });
        if (this.$route.query.province) {
          this.province = this.$route.query.province;
          this.areaList = [];
          this.areaList.unshift({
            label: '不限',
            value: ''
          });
          cityFind({
            provinceCode: this.province
          }).then(res => {
            this.cityList = res.data.data.map(item => {
              return {
                label: item.name,
                value: item.code
              };
            });
            this.cityList.unshift({
              label: '不限',
              value: ''
            });
            if (this.$route.query.city) {
              this.city = this.$route.query.city;
              areaFind({
                cityCode: this.city
              }).then(res => {
                this.areaList = res.data.data.map(item => {
                  return {
                    label: item.name,
                    value: item.code
                  };
                });
                this.areaList.unshift({
                  label: '不限',
                  value: ''
                });
                if (this.$route.query.area) {
                  this.area = this.$route.query.area;
                }
              });
            }
          });
        }
        // if (this.$route.query.area) {
        //   this.areaList = [];
        //   this.areaList.unshift({ label: '不限', value: '' });
        //   cityFind({ provinceCode: this.province }).then((res) => {
        //     this.cityList = res.data.data.map((item) => {
        //       return {
        //         label: item.name,
        //         value: item.code,
        //       };
        //     });
        //     this.cityList.unshift({ label: '不限', value: '' });
        //     this.city = this.$route.query.city;
        //     areaFind({ cityCode: this.city }).then((res) => {
        //       this.areaList = res.data.data.map((item) => {
        //         return {
        //           label: item.name,
        //           value: item.code,
        //         };
        //       });
        //       this.areaList.unshift({ label: '不限', value: '' });
        //       this.area = this.$route.query.area
        //     });
        //   });
        // };
      });
    },

    getPosition() {
      positionList({
        projectCode: this.$route.query.projectCode
      }).then(res7 => {
        this.positionList = res7.data.data.map(item => {
          return {
            label: item.positionName,
            value: item.positionCode
          };
        });
        this.positionList.unshift({
          label: '不限',
          value: ''
        });
        if (this.$route.query.position) {
          this.position = this.$route.query.position;
        }
      });
    },
    getCity() {
      console.log(this.province);
      this.areaList = [];
      this.areaList.unshift({
        label: '不限',
        value: ''
      });
      this.area = '';
      // this.cityList = [];
      cityFind({
        provinceCode: this.province
      }).then(res => {
        this.cityList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList.unshift({
          label: '不限',
          value: ''
        });
        this.city = '';
        this.getData();
      });
    },
    getArea() {
      console.log(111);
      // this.areaList = [];
      areaFind({
        cityCode: this.city
      }).then(res => {
        this.areaList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.areaList.unshift({
          label: '不限',
          value: ''
        });
        this.area = '';
        this.getData();
      });
    },
    goPlanDetail(orgTerminalCode, type1, status, planCode) {
      this.$router.push({
        path: '/TerminalPlanDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type,
          planCode: planCode,
          type1: type1,
          status1: status,
          orgTerminalCode: orgTerminalCode,
          position: this.position,
          status: this.status,
          types: this.types,
          province: this.province,
          city: this.city,
          area: this.area,
          Terminal: true
        }
      });
    },
    goback() {
      this.$router.push({
        path: '/realtimedynamicDetails',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type
        }
      });
      // window.history.back()
    },

    updatesearch() {
      this.getData();
    },
    onLoad() {
      console.log('kaishi');
      terminalPlanList({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        // month:this.$route.query.month,
        pageNumber: this.pageNumber++,
        pageSize: 10,
        status: this.status,
        province: this.province,
        city: this.city,
        district: this.area,
        positionCode: this.$route.query.position,
        terminalName: this.searchname,
        type: this.types
      }).then(res => {
        let list = this.listData;
        res.data.data.map(res1 => {
          list.push(res1);
        });
        this.listData = list;
        this.listDatalength = res.data.total;
        this.loading = false;
        if (this.listData.length >= res.data.total) {
          this.finished = true;
        }
      });
    }
  }
};